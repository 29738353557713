import axios from 'axios'

const Ajax = axios.create({
    baseURL:'/apis'
})


export default req => {
    return new Promise((resolve, reject) =>{
        Ajax.request({
            url:req.url,
            method: req.method || 'GET',
            params:req.params || {},
            data:req.data ||{}
        }).then(({ data }) =>{
            resolve(data)
        }).catch(e => {
            reject(e)
        })
    })
}