import Ajax from './ajax'

const getArticle = params =>{
    return Ajax({
        url:'https://b.laomaaijia.com/api/h5app/wxapp/article',
        params
    })
}

const getAppDownloads = params =>{
    return Ajax({
        url:'https://b.laomaaijia.com/api/website/version/download',
		method: 'GET',
        params
    })
}

export{
    getArticle,
	getAppDownloads
}